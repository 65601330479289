import { MuiTypography } from '@/components/mui/Typography';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

interface BaseRedirectProps {
  to: string;
  /**
   * Number of seconds to wait before redirecting.
   */
  after?: number;
  onRedirect?: () => void;
}

export default function BaseRedirect({
  to,
  after = 0,
  onRedirect,
}: BaseRedirectProps) {
  useEffect(() => {
    const timer = setTimeout(() => {
      onRedirect?.();
      window.location.replace(to);
    }, after * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [after, onRedirect, to]);

  return (
    <MuiTypography variant="subtitle1">
      <FormattedMessage defaultMessage="You'll be redirected shortly..." />
    </MuiTypography>
  );
}
