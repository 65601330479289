import { WORKER_BASE_PATH } from '@/utils/worker/constants';
import { useParams } from 'react-router-dom';

export const COMPLIANCE_PATH = '/compliance';

export function useWorkerPath() {
  const { workerSlug, companySlug } = useParams();

  if (!workerSlug || !companySlug) {
    return {
      workerHome: '',
      workerHomeCompliance: '',
    };
  }

  const workerHome = `${WORKER_BASE_PATH}/${companySlug}/${workerSlug}`;
  const workerHomeCompliance = `${workerHome}${COMPLIANCE_PATH}`;

  return {
    workerHome,
    workerHomeCompliance,
  };
}
